import React, { useState } from "react";
import { css } from "@emotion/react";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Address, InputValues, PhoneInput } from "@ryerson/frontend.form-controls";
import { Button } from "@ryerson/frontend.button";
import { useApplication } from "@ryerson/frontend.application";
import { useTheme } from "@ryerson/frontend.theme";
import { withLanguage } from "./UserAddressContent";
import { navigate } from "gatsby";
import styled from "@emotion/styled";
import { PostalCodeRegexPatterns, validatePhone } from "@ryerson/frontend.common";
import { APIUrl } from "@enums/apiUrl.enums";
import { Media } from "@ryerson/frontend.layout";
import { CommonShipmentEnum, Company } from "@ryerson/common.enums";
import { sanitizeInput } from "@components/Utilities/Utilities";
import { companyLogoCheck } from "@components/Shared/CompanyLogoCheck";

export interface IAccountRequest {
	email: string;
	password: string;
}

export interface UserAddressProps {
	account: IAccountRequest;
}

const inputStyling = css`
	margin-top: 20px;
`;

const SkipLink = styled.div`
	cursor: pointer;
`;

const UserAddress: React.FC<UserAddressProps> = ({ account }) => {
	const {
		user,
		profile,
		axiosInstance,
		localization: { language },
	} = useApplication();
	const content = withLanguage[language];
	const { theme } = useTheme();
	const { white, gray, primaryBrand } = theme.colors.primary;
	const [submitted, setSubmitted] = useState<boolean>(false);
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const companyEnviroment = process.env.GATSBY_COMPANY as Company;
	const isSTS = companyEnviroment === Company.SOUTHERN_TOOL_STEEL;

	const defaultValue: InputValues = {
		country: "us",
		streetAddressOne: "",
		streetAddressTwo: "",
		city: "",
		stateProvince: "",
		zipPostal: "",
	};

	const getInputValues = (): InputValues => {
		return { ...defaultValue };
	};

	const [value, setValue] = useState<InputValues>({
		...getInputValues(),
	});

	const handleAddressChange = (val: string | number, key: keyof InputValues) => {
		setValue((prev: any) => {
			return key === "country"
				? {
						...defaultValue,
						[key]: `${val}`,
				  }
				: { ...prev, [key]: `${val}` };
		});
	};

	const checkConfirmButton = () => {
		return value.streetAddressOne &&
			value.city &&
			value.stateProvince &&
			value.zipPostal &&
			validateZipCode() &&
			validatePhoneNumber() &&
			value.country &&
			phoneNumber
			? false
			: true;
	};

	const isButtonDisabled = () => {
		return !submitted ? checkConfirmButton() : submitted;
	};

	const validatePhoneNumber = () => {
		return validatePhone(phoneNumber, true) === "";
	};
	const validateZipCode = () => {
		let countryInput = sanitizeInput(value.country, 2);
		let re = PostalCodeRegexPatterns[countryInput as keyof typeof PostalCodeRegexPatterns];
		// deepcode ignore reDOS: Enum not user input, choosing to ignore.
		let regex = new RegExp(re, "g");
		return regex.test(value.zipPostal);
	};

	const navigateToDashboard = () => {
		navigate("/");
	};

	const addAddress = () => {
		let body = {
			fullName: `${user?.firstName} ${user?.lastName}`,
			companyName: `${profile?.company?.companyName}`,
			addressCategory: CommonShipmentEnum.AddressCategory.SoldTo,
			phoneNumber: phoneNumber,
			address1: value.streetAddressOne,
			address2: value.streetAddressTwo,
			city: value.city,
			state: value.stateProvince,
			zipCode: value.zipPostal,
			country: value.country,
		};

		axiosInstance
			.post(`${APIUrl.ADDRESS_COMFIRMATION}`, body)
			.then((response: any) => {
				if (response.status && response.status === 200) {
					navigate("/store/dashboard", { state: { user } });
					setSubmitted(true);
				}
			})
			.catch((error) => {
				setSubmitted(false);
				console.warn(error);
			});
	};

	const UserAddressContent = (isMobile: boolean = false): JSX.Element => {
		return (
			<Flex
				justifyContent="center"
				direction="column"
				alignItems={isMobile ? "flex-start" : "center"}
				style={css`
					padding: ${isMobile ? "0" : "50px"};
					margin-bottom: ${isMobile ? "60px" : "0"};
				`}
			>
				<Flex
					direction="column"
					justifyContent="center"
					alignItems="center"
					style={css`
						width: ${isMobile ? "100%" : "560px"};
						background-color: ${isMobile ? "none" : white};
						padding: ${isMobile ? 0 : "50px 60px 68px 60px"};
					`}
				>
					{!isMobile && companyLogoCheck(companyEnviroment)}
					<FlexItem css={{ textAlign: "center" }}>
						<Typography
							variant="h4"
							weight="bolder"
							css={{ marginTop: isMobile ? "12px" : isSTS ? "0px" : "42px" }}
							autoResize={false}
						>
							{content.title}
						</Typography>

						<Typography
							variant="div"
							size="md"
							color={gray}
							css={{
								marginTop: isMobile ? "17px" : "15px",
							}}
						>
							{content.subTitle}
						</Typography>
					</FlexItem>
					<FlexItem css={{ marginTop: isMobile ? "38px" : "43px", width: "100%" }}>
						<Address
							language={language}
							handleChange={handleAddressChange}
							inputValues={value}
							submitted={submitted}
							isMobile={isMobile}
						/>
						<PhoneInput
							label={content.phoneLabel}
							required
							value={phoneNumber}
							onChange={(e) => setPhoneNumber(e.target.value)}
							submitted={submitted}
							isValid={submitted ? validatePhoneNumber() : true}
							css={inputStyling}
							language={language}
						/>
					</FlexItem>
					<FlexItem
						style={css`
							width: ${isMobile ? "100%" : "239px"};
							margin-top: ${isMobile ? "26px" : "32px"};
						`}
					>
						<Button
							label={content.confirmButton}
							onClick={addAddress}
							disabled={isButtonDisabled()}
							fullwidth
						/>
					</FlexItem>
					<SkipLink onClick={navigateToDashboard}>
						<Typography
							variant="div"
							size="md"
							color={primaryBrand}
							css={{
								marginTop: isMobile ? "32px" : "42px",
							}}
						>
							{content.skipLinkText}
						</Typography>
					</SkipLink>
				</Flex>
			</Flex>
		);
	};
	return (
		<>
			<Media lessThan="lg">{UserAddressContent(true)}</Media>
			<Media greaterThanOrEqual="lg">{UserAddressContent(false)}</Media>
		</>
	);
};

export default UserAddress;
